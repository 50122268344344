"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getDatadogEnvironment = void 0;

var getDatadogEnvironment = function getDatadogEnvironment(dotcomEnvironment) {
  switch (dotcomEnvironment) {
    case 'local':
      return 'dev';

    case 'uat':
      return 'uat-test';

    case 'qa':
      return 'qa-test';

    case 'release':
      return 'release';

    case 'prod':
      return 'prod';

    default:
      return 'unknown';
  }
};

exports.getDatadogEnvironment = getDatadogEnvironment;