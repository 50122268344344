"use strict";

require("core-js/modules/es6.object.keys.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es7.object.get-own-property-descriptors.js");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.modalLoad = exports.load = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.promise.js");

require("core-js/modules/es6.array.filter.js");

require("core-js/modules/es6.symbol.js");

require("core-js/modules/es6.array.from.js");

require("core-js/modules/es6.string.iterator.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

require("core-js/modules/es6.function.name.js");

var _immutable = require("immutable");

var _momentEs = _interopRequireDefault(require("moment-es6"));

var _serializeJavascript = _interopRequireDefault(require("serialize-javascript"));

var _Store = _interopRequireDefault(require("./State/Store"));

var _Config = require("./State/Ducks/Config");

var _Locale = require("./State/Ducks/Locale");

var _UserLocation = require("./State/Ducks/UserLocation");

var _Schema = require("./State/Ducks/Schema");

var _Labels = require("./State/Ducks/Labels");

var _FlightSchedule = require("./State/Ducks/FlightSchedule");

var _strings = require("./Utils/strings");

var _api = require("./Utils/api");

var _functions = require("./Utils/functions");

var _enAu = _interopRequireDefault(require("./Lib/MomentLocale/en-au"));

var _flockApi = require("@jetstarairways/flock-api");

var _browserRum = require("@datadog/browser-rum");

var _environment = require("./Utils/environment");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var mapExtractGuid = (0, _strings.extractGuid)(false);
var modulesList = [];
var componentsList = [];
var requestsMap = (0, _immutable.Map)();

var getGuid = function getGuid(guid) {
  if (!guid) {
    return '';
  }

  return mapExtractGuid(guid) || guid;
};

var fetch = function fetch(fn) {
  return new Promise(function (resolve, reject) {
    _Store.default.dispatch(fn).then(function (response) {
      resolve(response);
    }).catch(reject);
  });
};

var loadModule = function loadModule(guid, callback, componentDataApiUrl) {
  if (!guid) {
    return;
  }

  var elem = document.querySelector("[data-module-id=\"".concat(guid, "\"]"));

  if (!elem) {
    return;
  }

  var dataset = elem.dataset;
  var schemaName = dataset.schemaName,
      sourceIdentifier = dataset.sourceIdentifier;
  var sanitisedSourceIdentity = getGuid(sourceIdentifier);
  var moduleConfig = sanitisedSourceIdentity ? requestsMap.get(sanitisedSourceIdentity) : {};
  var componentsData = sanitisedSourceIdentity ? [moduleConfig(componentDataApiUrl)] : [];
  var moduleComponents = componentsList.filter(function (component) {
    return component.moduleId === guid;
  });
  Array.from(moduleComponents).forEach(function (comp) {
    var sourceIdentity = comp.sourceIdentity;
    componentsData.push(requestsMap.get(sourceIdentity)(componentDataApiUrl));
  }); // dataset gets reset within Tabs so check doesn't do anything in that context

  if (dataset.initialized !== 'true') {
    (0, _api.fetchAllRequests)(componentsData).then(function (response) {
      dataset.initialized = 'true';
      callback(_Store.default, schemaName, elem, sanitisedSourceIdentity, (0, _immutable.fromJS)(moduleComponents));
    });
  } else {
    callback(_Store.default, schemaName, elem, sanitisedSourceIdentity, (0, _immutable.fromJS)(moduleComponents));
  }
};

var getStorageKey = function getStorageKey(siteValue) {
  return "".concat(siteValue.get('locale'), "-").concat(siteValue.get('SearchFlightVersion').trim() || 'default');
};

var getSiteValues = function getSiteValues(url) {
  return fetch((0, _Config.fetchConfig)(url));
};

var getFlightSchedule = function getFlightSchedule(siteValue) {
  return fetch((0, _FlightSchedule.loadFlightSchedule)({
    url: siteValue.get('schedulesApiUrl'),
    storageKey: getStorageKey(siteValue)
  }));
};

var getLocaleSettings = function getLocaleSettings(siteValue) {
  return fetch((0, _Locale.fetchLocale)(siteValue.get('locale')));
};

var getSiteLabels = function getSiteLabels(siteValue) {
  return fetch((0, _Labels.fetchLabels)(siteValue.get('siteLabelsApiUrl')));
};

var getUserLocation = function getUserLocation(siteValue) {
  return fetch((0, _UserLocation.fetchUserLocation)());
};

var getComponentData = function getComponentData(options, elem) {
  return function (url) {
    var currentPageId = options.currentPageId,
        imageMapping = options.imageMapping,
        nestedSerialization = options.nestedSerialization,
        renderingId = options.renderingId,
        sourceIdentity = options.sourceIdentity;
    var apiUrl = "".concat(url, "?itemId=").concat(currentPageId, "&renderingId=").concat(renderingId, "&nestedSerialization=").concat(nestedSerialization, "&imageMapping=").concat(imageMapping);
    var sanitisedSourceIdentity = getGuid(sourceIdentity);
    return fetch((0, _Schema.fetchComponentData)(apiUrl, sanitisedSourceIdentity));
  };
};

var updateRequestsMap = function updateRequestsMap(data) {
  var currentPageId = data.currentPageId,
      imageMapping = data.imageMapping,
      moduleId = data.moduleId,
      nestedSerialization = data.nestedSerialization,
      renderingId = data.renderingId,
      sourceIdentity = data.sourceIdentity;

  if (!sourceIdentity) {
    return;
  }

  if (requestsMap.size && requestsMap.get(sourceIdentity)) {
    return;
  }

  requestsMap = requestsMap.set(sourceIdentity, (0, _functions.memo)(getComponentData({
    currentPageId: currentPageId,
    imageMapping: imageMapping,
    moduleId: moduleId,
    nestedSerialization: nestedSerialization,
    renderingId: renderingId,
    sourceIdentity: sourceIdentity
  })));
};

var addQueue = function addQueue(moduleId, currentPageId, callback) {
  var moduleContainer = document.querySelector("[data-module-id=\"".concat(moduleId, "\"]"));

  if (!moduleContainer || moduleContainer.dataset.initialized === 'true') {
    return;
  }

  var moduleSourceIdentity = getGuid(moduleContainer.dataset.sourceIdentifier);
  var moduleName = moduleContainer.dataset.name || '';
  var moduleReducer = moduleContainer.dataset.reducer || '';
  var nestedSerialization = moduleContainer.dataset.nestedSerialization || 'false';
  var imageMapping = moduleContainer.dataset.imageMapping || 'false'; // NOTE:
  // nestedSerialization (data-nested-serialization): this flag tells the backend to use nested serialization instead of returning a guid used for some keys in getComponentData response
  // imageMapping (data-image-mapping): this flag tells the backend to return image sources based on a recipe (e.g. data-image-mapping="imageFieldName:RecipeName|imageFieldName2:RecipeName2")

  if (!modulesList.filter(function (m) {
    return m.moduleId === moduleId;
  }).length) {
    modulesList.push({
      moduleId: moduleId,
      moduleSourceIdentity: moduleSourceIdentity,
      callback: callback,
      moduleName: moduleName,
      reducer: moduleReducer
    });
  }

  updateRequestsMap({
    currentPageId: currentPageId,
    imageMapping: imageMapping,
    moduleId: moduleId,
    nestedSerialization: nestedSerialization,
    reducer: moduleReducer,
    renderingId: moduleId,
    sourceIdentity: moduleSourceIdentity
  });
  Array.from(moduleContainer.querySelectorAll("[data-react-id]")).filter(function (node) {
    return node.parentNode === moduleContainer;
  }).forEach(function (elem) {
    var dataset = elem.dataset;
    var reactId = dataset.reactId,
        name = dataset.name,
        componentIdentity = dataset.sourceIdentifier;
    var componentSourceIdentity = getGuid(componentIdentity);

    if (!componentsList.filter(function (c) {
      return c.renderingId === reactId;
    }).length) {
      componentsList.push({
        renderingId: reactId,
        sourceIdentity: componentSourceIdentity,
        moduleId: moduleId,
        name: name,
        reducer: moduleReducer
      });
    }

    updateRequestsMap({
      currentPageId: currentPageId,
      moduleId: moduleId,
      imageMapping: imageMapping,
      nestedSerialization: nestedSerialization,
      reducer: moduleReducer,
      renderingId: reactId,
      sourceIdentity: componentSourceIdentity
    });
  });
};

var run = function run(siteValuesApiUrl, moduleId, dependencies) {
  var flightSchedule = dependencies.flightSchedule,
      siteValues = dependencies.siteValues,
      locale = dependencies.locale,
      userLocation = dependencies.userLocation,
      siteLabels = dependencies.siteLabels;
  var getDependencies = (0, _api.fetchAllRequests)([flightSchedule, locale, userLocation, siteLabels]);
  siteValues.then(function (data) {
    var jedisConfiguration = data.get('jedis');
    var posCode = data.get('posCode');

    if (!jedisConfiguration || !posCode) {
      return data; //in the future if want to stop processing future if no jedis setting we can throw error instead
    }

    var jedisApiUrl = jedisConfiguration.get('apiUrl');

    if (!jedisApiUrl) {
      return data; //in the future if want to stop processing future if no jedis setting we can throw error instead
    }

    var withCredentials = jedisConfiguration.get('withCredentials') || false;

    _flockApi.api.init({
      baseUrl: jedisApiUrl,
      culture: posCode,
      withCredentials: withCredentials
    });

    return data;
  }).then(getDependencies).then(function (data) {
    var componentDataApiUrl = data.get('componentDataApiUrl');

    if (!componentDataApiUrl) {
      return;
    }

    flightSchedule.then(function (schedule) {
      var requestedModule = modulesList.filter(function (module) {
        return module.moduleId === moduleId;
      });
      requestedModule.forEach(function (_ref) {
        var id = _ref.moduleId,
            fn = _ref.callback;
        loadModule(id, fn, componentDataApiUrl);
      });
    });
  }).catch(function (err) {});
};

var loader = function loader(settings, pageId) {
  if (!settings) {
    return function () {};
  }

  var _deSerialize = (0, _strings.deSerialize)(settings),
      siteValuesApiUrl = _deSerialize.siteValuesApiUrl,
      currentPageId = _deSerialize.currentPageId;

  var siteValues = getSiteValues(siteValuesApiUrl);
  var flightSchedule = siteValues.then(getFlightSchedule);
  var locale = siteValues.then(getLocaleSettings);
  var userLocation = flightSchedule.then(getUserLocation);
  var siteLabels = siteValues.then(getSiteLabels);
  var dependencies = {
    flightSchedule: flightSchedule,
    siteValues: siteValues,
    locale: locale,
    userLocation: userLocation,
    siteLabels: siteLabels
  };
  return function (moduleId, callback) {
    if (!moduleId) {
      return;
    }

    addQueue(moduleId, pageId || currentPageId, callback);
    run(siteValuesApiUrl, moduleId, dependencies);
  };
};

if (window.datadog_settings && !_browserRum.datadogRum.getInitConfiguration()) {
  _browserRum.datadogRum.init(_objectSpread(_objectSpread({}, window.datadog_settings), {}, {
    env: (0, _environment.getDatadogEnvironment)(window.Jetstar.buildInfo.env),
    version: window.Jetstar.buildInfo.version,
    // TODO: move these to sitecore settings
    trackResources: true,
    trackLongTasks: true,
    enablePrivacyForActionName: true
  }));

  _browserRum.datadogRum.startSessionReplayRecording();
} // IMPORTANT: this will update the default moment locale ("en") with our config for "en-au". This is specially important because of the start of the week (dow: 1 = Monday)


_momentEs.default.updateLocale(_momentEs.default.locale(), _enAu.default);

var load = loader((0, _serializeJavascript.default)(window.Jetstar, {
  isJSON: true
}));
exports.load = load;

var modalLoad = function modalLoad(pageId) {
  return loader((0, _serializeJavascript.default)(window.Jetstar, {
    isJSON: true
  }), pageId);
};

exports.modalLoad = modalLoad;